import React, { Dispatch, SetStateAction } from 'react';
import {
	Button as ChakraButton,
	Flex,
	Icon,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Select,
	Stack,
	Table,
	TableCaption,
	TableContainer,
	TableContainerProps,
	Tbody,
	Td,
	Tr,
} from '@chakra-ui/react';
import {
	AiFillCheckCircle,
	AiFillCloseCircle,
	AiFillExclamationCircle
} from 'react-icons/ai';
import {
	MdKeyboardArrowLeft,
	MdKeyboardArrowRight,
	MdKeyboardDoubleArrowLeft,
	MdKeyboardDoubleArrowRight,
	MdLock,
	MdLockOpen,
} from 'react-icons/md';

import { GetSellerDto, GetSellerDtoStatusEnum } from '../../../clients';
import { StatusInfo } from '../../Reports/interfaces/StatusInfo';
import { defaultColors } from '../../../config/variables';
import Text from '../../../components/Text';


type TableProps = TableContainerProps & {
	body: GetSellerDto[] | undefined;
	currentPage: number;
	limit: number;
	totalPages: number;
	setCurrentPage: Dispatch<SetStateAction<number>>;
	setLimit: Dispatch<SetStateAction<number>>;
	fetchData: (currentPage?: number, limit?: number) => Promise<void>;
	changeStatus: (
		sellerId: string,
		sellerName: string,
		sellerStatus: GetSellerDtoStatusEnum
	) => Promise<void>;
};

const paymentStatus: Record<GetSellerDtoStatusEnum, StatusInfo> = {
	[GetSellerDtoStatusEnum.Active]: {
		icon: <AiFillCheckCircle color='LimeGreen' size={16} />,
		text: 'Ativo',
	},
	[GetSellerDtoStatusEnum.Blocked]: {
		icon: <AiFillCloseCircle color='red' size={16} />,
		text: 'Bloqueado',
	},
	[GetSellerDtoStatusEnum.Inactive]: {
		icon: <AiFillExclamationCircle color='gray' size={16} />,
		text: 'Inativo',
	}
};

const SimpleTable = (props: TableProps) => {
	return (
		<>
			<TableContainer w='100%'>
				<Table w='100%' variant='simple' size='md' display={['none', 'none', 'table']}>
					{(!props?.body || props.body.length === 0) && <TableCaption>Sem Dados</TableCaption>}
					{props?.body?.map((body) => (
						<Tbody key={body.id} _hover={{ background: '#CCCCCC' }}>
							<Tr>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										CNS
									</Text>
									<div>
									{body?.cns ? JSON.stringify(body?.cns)
											.replace(/"/g, '')
											.replace(/(\d{2})(\d)/, '$1.$2')
											.replace(/(\d{3})(\d)/, '$1-$2'): ''}
									</div>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										Nome
									</Text>
									<div>{body?.name}</div>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										EMAIL
									</Text>
									<div>{body?.email}</div>
								</Td>
								<Td>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										STATUS
									</Text>
									<Flex flexDirection='row' gap='5px' alignItems='center'>
										{paymentStatus[body.status as GetSellerDtoStatusEnum].icon}
										{paymentStatus[body.status as GetSellerDtoStatusEnum].text}
									</Flex>
								</Td>
								<Td>
									<Text textAlign={'center'} fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										{body.status === GetSellerDtoStatusEnum.Active && ('BLOQUEAR')}
										{body.status === GetSellerDtoStatusEnum.Blocked && ('DESBLOQUEAR')}
									</Text>
									<Flex flexDirection='row' justifyContent='center'>
										{body.status === GetSellerDtoStatusEnum.Active && (
											<ChakraButton
												fontSize='20px'
												variant='link'
												leftIcon={<Icon as={MdLock} />}
												onClick={()=> props.changeStatus(body.id, body.name, GetSellerDtoStatusEnum.Blocked)}
											/>
										)}
										{body.status === GetSellerDtoStatusEnum.Blocked && (
											<ChakraButton
												fontSize='20px'
												variant='link'
												leftIcon={<Icon as={MdLockOpen} />}
												onClick={
													()=> props.changeStatus(body.id, body.name, GetSellerDtoStatusEnum.Active)
												}
											/>
										)}
									</Flex>
								</Td>
							</Tr>
						</Tbody>
					))}
				</Table>
				<Table variant='simple' size='md' display={['flex', 'flex', 'none']} flexDirection='column' w='100%'>
					{(!props?.body || props.body.length === 0) && <TableCaption>Sem Dados</TableCaption>}
					{props?.body?.map((body) => (
						<Tbody
							w='100%'
							display='flex'
							alignItems='center'
							flexDirection='column'
							p='10px'
							bg={defaultColors.white}
							boxShadow='0px 2px 4px rgba(0, 0, 0, 0.1)'
							borderRadius='8px'
							border='2px solid rgba(0, 0, 0, 0.2)'
							mb='10px'
							_hover={{ background: defaultColors.white }}
						>
							<Tr w='100%' display='flex' flexDirection='column' alignItems='center' gap='12px'>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										CNS:
									</Text>
									<div>
										{body?.cns ? body?.cns
											.replace(/"/g, '')
											.replace(/(\d{2})(\d)/, '$1.$2')
											.replace(/(\d{3})(\d)/, '$1-$2'): ''}
									</div>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px' maxW={'100%'}>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										Nome:
									</Text>
									<div>
										{
											body?.name.length > 35 ? `${body?.name.substring(0, 35)}...` : body?.name
										}
									</div>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										EMAIL:
									</Text>
									<div>{body?.email}</div>
								</Flex>
								<Flex flexDirection='row' alignItems='center' gap='8px'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										STATUS:
									</Text>
									<Flex flexDirection='row' gap='12px' alignItems='center'>
										{paymentStatus[body.status as GetSellerDtoStatusEnum].text}
									</Flex>
								</Flex>

								<Flex flexDirection='row' alignItems='center' gap='8px' whiteSpace='normal'>
									<Text fontWeight='bold' fontSize='12px' color={defaultColors.primaryColor}>
										{body.status === GetSellerDtoStatusEnum.Active && ('BLOQUEAR')}
										{body.status === GetSellerDtoStatusEnum.Blocked && ('DESBLOQUEAR')}
									</Text>
									{body.status === GetSellerDtoStatusEnum.Active && (
										<ChakraButton
											fontSize='20px'
											variant='link'
											leftIcon={<Icon as={MdLock} />}
											onClick={()=> props.changeStatus(body.id, body.name, GetSellerDtoStatusEnum.Blocked)}
										/>
									)}
									{body.status === GetSellerDtoStatusEnum.Blocked && (
										<ChakraButton
											fontSize='20px'
											variant='link'
											leftIcon={<Icon as={MdLockOpen} />}
											onClick={
												()=> props.changeStatus(body.id, body.name, GetSellerDtoStatusEnum.Active)
											}
										/>
									)}
								</Flex>
							</Tr>
						</Tbody>
					))}
				</Table>
			</TableContainer>
			{props?.body && props.body.length > 0 && (
				<Stack justifyContent='space-around' flexDirection={['column', 'row']}>
					{props.currentPage > 1 && (
						<Stack flexDirection='row' justifyContent='center'>
							<ChakraButton
								variant='link'
								id='button__double-arrow-left'
								color='black'
								onClick={() => {
									if (props.currentPage > 1) {
										props.setCurrentPage(1);
										props.fetchData(1, props.limit);
									}
								}}
							>
								<MdKeyboardDoubleArrowLeft size='20px' />
							</ChakraButton>
							<ChakraButton
								variant='link'
								id='button__arrow-left'
								color='black'
								onClick={() => {
									if (props.currentPage > 1) {
										const previousPage = props.currentPage - 1;
										props.setCurrentPage(previousPage);
										props.fetchData(previousPage, props.limit);
									}
								}}
							>
								<MdKeyboardArrowLeft size='20px' />
							</ChakraButton>
						</Stack>
					)}
					<Stack>
						<Text>
							<Stack flexDirection='row' alignItems='center' justifyContent='space-around' spacing='30px'>
								<Stack>
									<Text textAlign={['center']}>
										 Página {props.currentPage} de {props.totalPages}
									</Text> 
								</Stack>
								<Stack flexDirection='row' alignItems='center'>
									<Text>Ir para página: </Text>
									<NumberInput
										id='input__page-number'
										min={1}
										max={props.totalPages}
										w='50%'
										minW='70px'
										defaultValue={props.currentPage}
										value={props.currentPage}
										onChange={(e) => {
											const newPage = Number(e);
											props.setCurrentPage(newPage);
											if (props.totalPages && newPage >= 1 && newPage <= props.totalPages) {
												props.fetchData(newPage, props.limit);
											}
										}}
									>
										<NumberInputField
											min={1}
											max={props.totalPages}
											border='2px solid black'
											borderRadius='15px'
											_disabled={{
												color: 'black',
											}}
										/>
										<NumberInputStepper w='30%'>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput> 
								</Stack>
								<Stack>
									<Select
										id='input_select'
										defaultValue={10}
										w='60%'
										minW='120px'
										border='2px solid black'
										borderRadius='15px'
										value={props.limit}
										onChange={(e) => {
											const selectedValue = Number(e.target.value);
											props.setLimit(selectedValue);
											props.setCurrentPage(1);
											props.fetchData(1, selectedValue);
										}}
									>
										<option value={10}>Ver 10</option>
										<option value={20}>Ver 20</option>
										<option value={30}>Ver 30</option>
										<option value={40}>Ver 40</option>
										<option value={50}>Ver 50</option>
										<option value={100}>Ver 100</option>
									</Select>
								</Stack>
							</Stack>
						</Text>
					</Stack>
					{props.currentPage < props.totalPages && (
						<Stack flexDirection='row'  justifyContent='center'>
							<ChakraButton
								variant='link'
								color='black'
								id='button__arrow-right'
								onClick={() => {
									const totalPages = props?.totalPages || 1;
									if (props.currentPage < totalPages) {
										const nextPage = props.currentPage + 1;
										props.setCurrentPage(nextPage);
										props.fetchData(nextPage, props.limit);
									}
								}}
							>
								<MdKeyboardArrowRight size='20px' />
							</ChakraButton>
							<ChakraButton
								variant='link'
								color='black'
								id='button__double-arrow-right'
								onClick={() => {
									const totalPages = props?.totalPages || 1;
									if (props.currentPage < totalPages) {
										props.setCurrentPage(totalPages);
										props.fetchData(totalPages, props.limit);
									}
								}}
							>
								<MdKeyboardDoubleArrowRight size='20px' />
							</ChakraButton>
						</Stack>
					)}
				</Stack>
			)}
		</>
	);
};

export default SimpleTable;
