/* tslint:disable */
/* eslint-disable */
/**
 * Shud CNTV API
 * Shud CNTV API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetPaginatedSellersDto,
  GetSellerDto,
  UpdateSellerDto,
} from '../models/index';
import {
    GetPaginatedSellersDtoFromJSON,
    GetPaginatedSellersDtoToJSON,
    GetSellerDtoFromJSON,
    GetSellerDtoToJSON,
    UpdateSellerDtoFromJSON,
    UpdateSellerDtoToJSON,
} from '../models/index';

export interface GetPaginatedSellersRequest {
    limit: number;
    currentPage: number;
    cns?: string;
    status?: GetPaginatedSellersStatusEnum;
}

export interface ImportSellersRequest {
    file: Blob;
    name?: string;
    contentType?: string;
}

export interface UpdateSellerRequest {
    id: string;
    updateSellerDto: UpdateSellerDto;
}

/**
 * 
 */
export class SellersApi extends runtime.BaseAPI {

    /**
     * Buscar cartórios por paginação
     */
    async getPaginatedSellersRaw(requestParameters: GetPaginatedSellersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPaginatedSellersDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getPaginatedSellers.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling getPaginatedSellers.');
        }

        const queryParameters: any = {};

        if (requestParameters.cns !== undefined) {
            queryParameters['cns'] = requestParameters.cns;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/paginated`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPaginatedSellersDtoFromJSON(jsonValue));
    }

    /**
     * Buscar cartórios por paginação
     */
    async getPaginatedSellers(requestParameters: GetPaginatedSellersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPaginatedSellersDto> {
        const response = await this.getPaginatedSellersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Buscar cartórios
     */
    async getSellersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetSellerDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSellerDtoFromJSON));
    }

    /**
     * Buscar cartórios
     */
    async getSellers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetSellerDto>> {
        const response = await this.getSellersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Importar cartórios
     */
    async importSellersRaw(requestParameters: ImportSellersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling importSellers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name as any);
        }

        if (requestParameters.contentType !== undefined) {
            formParams.append('contentType', requestParameters.contentType as any);
        }

        const response = await this.request({
            path: `/v1/sellers/import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Importar cartórios
     */
    async importSellers(requestParameters: ImportSellersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.importSellersRaw(requestParameters, initOverrides);
    }

    /**
     * Atualizar cartório
     */
    async updateSellerRaw(requestParameters: UpdateSellerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSellerDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSeller.');
        }

        if (requestParameters.updateSellerDto === null || requestParameters.updateSellerDto === undefined) {
            throw new runtime.RequiredError('updateSellerDto','Required parameter requestParameters.updateSellerDto was null or undefined when calling updateSeller.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSellerDtoToJSON(requestParameters.updateSellerDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerDtoFromJSON(jsonValue));
    }

    /**
     * Atualizar cartório
     */
    async updateSeller(requestParameters: UpdateSellerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSellerDto> {
        const response = await this.updateSellerRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetPaginatedSellersStatusEnum = {
    Active: 'active',
    Blocked: 'blocked',
    Inactive: 'inactive'
} as const;
export type GetPaginatedSellersStatusEnum = typeof GetPaginatedSellersStatusEnum[keyof typeof GetPaginatedSellersStatusEnum];
