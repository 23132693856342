import React from 'react';
import { Flex, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';


import { useDebounce } from '../../../services/useDebounce.service';
import { GetSellerDtoStatusEnum } from '../../../clients';
import { SellerToUpdate } from '../types/sellerToUpdate';
import Button from '../../../components/Button';
import Title from '../../../components/Title';
import Text from '../../../components/Text';


interface ConfirmModalTypes {
	isOpen: boolean;
	sellerToUpdate: SellerToUpdate;
	onClose: () => void;
	handleAcceptModal: (sellerId: string, status: GetSellerDtoStatusEnum) => Promise<void>;
}

const ConfirmModal: React.FC<ConfirmModalTypes> = (props: ConfirmModalTypes) => {
	const [isAcceptLoading, setIsAcceptLoading] = React.useState<boolean>(false);

	const handleDecline = () => {
		props.onClose();
	};

	const handleAccept = useDebounce(() => {
		props.handleAcceptModal(props.sellerToUpdate.id, props.sellerToUpdate.status);
		props.onClose();
		setIsAcceptLoading(false);
	}, 300);

	return (
		<Modal isOpen={props.isOpen} onClose={handleDecline} closeOnOverlayClick={false} size='lg'>
			<ModalOverlay />
			<ModalContent>
				<Flex padding='20px' flexDirection='column'>
					<Title textAlign='center' fontSize='2xl' borderBottom='1px solid grey'>
						Confirmar atualização do cartório
					</Title>
					<Flex mt='15px'>
						<Text>
							Confirma o
							<strong>{props.sellerToUpdate.status === GetSellerDtoStatusEnum.Blocked ? ' bloqueio': ' desbloqueio'} </strong>
							do {props.sellerToUpdate.name}
						</Text>
					</Flex>
					<Flex my='25px' justifyContent='space-around'>
						<Button type='submit' bgColor='red' onClick={handleDecline}>
							Não
						</Button>
						<Button
							isLoading={isAcceptLoading}
							type='submit'
							onClick={() => {
								setIsAcceptLoading(true);
								handleAccept();
							}}
						>
							Sim
						</Button>
					</Flex>
				</Flex>
			</ModalContent>
		</Modal>
	);
};

export default ConfirmModal;
