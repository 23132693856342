import React, { useEffect } from 'react';

import {
	Select as ChakraSelect,
	Input,
	Flex,
	InputGroup,
	InputLeftAddon,
	Spinner,
	Stack,
	useDisclosure,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { useToasts } from 'react-toast-notifications';

import { GetPaginatedSellersDto } from '../../clients/models/GetPaginatedSellersDto';
import { GetUserLoginSellerDtoStatusEnum } from '../../clients';
import { getApiAuthConfig } from '../../services/api.service';
import FormControl from '../../components/Form/FormControl';
import { SellerToUpdate } from './types/sellerToUpdate';
import { defaultColors } from '../../config/variables';
import { cnsMask } from '../../services/mask.service';
import ConfirmModal from './components/ConfirmModal';
import Button from '../../components/Button';
import SimpleTable from './Table/Table';
import {
	GetPaginatedSellersStatusEnum,
	GetSellerDtoStatusEnum,
	SellersApi,
} from '../../clients';



const Sellers: React.FC = () => {
	const { addToast } = useToasts();
	const apiConfig = getApiAuthConfig();
	const sellersApi = new SellersApi(apiConfig);

	const [sellers, setSellers] = React.useState<GetPaginatedSellersDto | undefined>(undefined);
	const [cns, setCns] = React.useState<string | undefined>(undefined);
	const [loading, setLoading] = React.useState<boolean>(true);
	const [status, setStatus] = React.useState<GetPaginatedSellersStatusEnum | undefined>();
	const [currentPage, setCurrentPage] = React.useState<number>(1);
	const [limit, setLimit] = React.useState<number>(10);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [sellerToUpdate, setSellerToUpdate] = React.useState<SellerToUpdate | undefined>(undefined);

	const initialValues = {
		cns,
		status
	};

	const  sellerStatusTranslations = {
		active: 'Ativo',
		blocked: 'Bloqueado',
		inactive: 'Inativo',
	};

	async function fetchData(newCurrentPage?: number, newLimit?: number) {
		setLoading(true);

		const sellersResponse = await sellersApi.getPaginatedSellers({
			currentPage: newCurrentPage || currentPage,
			limit: newLimit || limit,
			status,
			cns
		});
		setSellers(sellersResponse);

		setLoading(false);
	}

	async function changeStatus(sellerId: string, status: GetSellerDtoStatusEnum): Promise<void> {
		try {
			await sellersApi.updateSeller({ id: sellerId, updateSellerDto: { status }});
			addToast(`Cartório atualizado com sucesso.`, {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (error) {
			addToast(`Ocorreu um erro ao tentar atualizar cartório.`, {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			fetchData();
		}
	}

	const openModalToConfirm = async (sellerId: string, sellerName: string, sellerStatus: GetSellerDtoStatusEnum) => {
		setSellerToUpdate({
			id: sellerId,
			name: sellerName,
			status: sellerStatus
		})
		onOpen();
	}

	useEffect(() => {
		fetchData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Flex flexDirection='column' w='100%' px={4} pt='20px' minH='88%' overflowX='auto'>
			<Flex flexDirection='column' w='100%' alignItems={['center', 'center', 'start']}>
				<Flex>
					{sellerToUpdate && (
						<ConfirmModal
							sellerToUpdate={sellerToUpdate}
							isOpen={isOpen}
							onClose={onClose}
							handleAcceptModal={changeStatus}
						/>
					)}
				</Flex>
				<Formik enableReinitialize initialValues={initialValues} onSubmit={()=>{}}>
					{() => {
						return (
							<form>
								<Stack
									direction={['column', 'column', 'row']}
									alignItems={['center', 'center', 'flex-start']}
									spacing='20px'
									mb='20px'
									mt='40px'
									w='100%'
									justify='flex-start'
									wrap='wrap'
								>
										<Flex w='250px'>
											<FormControl>
												<Input
													id='cns'
													name='cns'
													placeholder='CNS'
													border='2px solid black'
													borderRadius='15px'
													maxLength={7}
													value={cns}
													onChange={(e) => setCns(cnsMask(e.target.value))}
												/>
											</FormControl>
										</Flex>
									<Stack spacing={4} w='250px'>
										<InputGroup size='md' border='2px solid black' borderRadius='15px'>
											<InputLeftAddon bgColor='lightgray' border='none' borderRadius='15px 0px 0px 15px'>
												Status
											</InputLeftAddon>
											<ChakraSelect
												name='status'
												id='input__status'
												borderRadius='0px 15px 15px 0px'
												onChange={(e) => {
													if (e.target.value === '') {
														setStatus(undefined)
													} else {
														setStatus(e.target.value as GetUserLoginSellerDtoStatusEnum)
													}
												}}
												border='none'
												value={status}
											>
												<option value=''>Todos</option>
												{Object.entries(GetUserLoginSellerDtoStatusEnum).map(([key, value]) => (
													<option key={key} value={value}>
														{sellerStatusTranslations[value]}
													</option>
												))}
											</ChakraSelect>
										</InputGroup>
									</Stack>
									<Flex maxW='200px' flex='0 1 auto'>
										<FormControl>
											<Button
												id='button__search'
												type='submit'
												size='md'
												isLoading={loading}
												bgColor={defaultColors.primaryColor}
												borderRadius='10px'
												w='100%'
												onClick={() => fetchData()}
											>
												Buscar
											</Button>
										</FormControl>
									</Flex>
								</Stack>
							</form>
						);
					}}
				</Formik>
				<Stack w='100%' mb={4} alignItems='center' mt='40px'>
					{loading ? (
						<Spinner size='lg' />
					) : (
						<>
							<SimpleTable
								limit={limit}
								currentPage={currentPage}
								totalPages={sellers?.total_pages || 0}
								fetchData={fetchData}
								setCurrentPage={setCurrentPage}
								setLimit={setLimit}
								changeStatus={openModalToConfirm}
								body={sellers?.results}
							/>
						</>
					)}
				</Stack>
			</Flex>
		</Flex>
	);
};

export default Sellers;
