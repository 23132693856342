import React from 'react';
import { Flex, Image } from '@chakra-ui/react';

import Text from '../components/Text';


const AccessBlocked: React.FC = () => {
  return (
    <Flex height={'90%'} direction='column' justifyContent={'space-around'}>
      <Flex direction='column' alignItems='center' >
        <Image src={require('../assets/images/atencao.png')} w={[150, 150, 200]} />
        <Text textAlign={'center'}>
          Funcionalidade suspensa devido à existência de boletos vencidos!
        </Text>
        <br/>
        <Text textAlign={'center'}>
          Para retomar o acesso, entre em contato via central de suporte para a reemissão do boleto e quitação dos débitos.
        </Text>
      </Flex>
    </Flex>
  );
}

export default AccessBlocked;